<template>
    <nav class="navbar navbar-expand-lg px-0 my-4">
        <a
            v-for="item in menuItems"
            :key="item.title"
            class="u-nav-item"
            :u-nav-current="item.selected"
            href=""
            @click="item.onclick"
        >
            <span class="xs-hide">
                {{ item.title }}
            </span>
            <span class="xs-show">
                <i :class="item.mobileIconClass" />
            </span>
        </a>
    </nav>
</template>

<script>
export default {
    /*
    [
        {
            title: '',
            onclick: () => {},
            selected: '',
            mobileIconClass: ''
        }
    ]
    */
    props: {
        menuItems: {
            type: Array(Object),
            required: true,
        },
    },

    data() {
        return {
            isCollapsed: true
        }
    },

    created() {
        window.addEventListener('resize', this.resize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resize);
    },

    updated() {
        this.resize();
    },

    methods: {
        resize() {
            const elem = this.$refs.content;

            //Update the content max-height for dynamic DOM changes
            if (!this.isCollapsed)
                elem.style.maxHeight = elem.scrollHeight + 'px';
        },

        collapse(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            const elem = this.$refs.content
            elem.style.maxHeight = this.isCollapsed ? elem.scrollHeight + 'px' : '0px';

            this.isCollapsed = !this.isCollapsed;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../_custom.scss';

nav {
    flex-wrap: wrap;
    overflow-x: auto;
}

@include media(xs) {
    a.u-nav-item {
        flex-grow: 1;
        flex-basis: 0;
        text-align: center;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.content {
    overflow: hidden;
    transition: max-height ease-in-out .3s;
}
</style>
